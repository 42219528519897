import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme/theme';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CompanyDetails from './pages/CompanyDetails';
import Home from './pages/Home';
import Investments from './pages/Investments';
import Profile from './hocs/Profile';
import Order from './hocs/Order';
import Portfolio from './pages/Portfolio';
import HowItWorks from './pages/HowItWorks';
import RuleBook from './pages/RuleBook';
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';

import RequestReset from './pages/RequestReset';
import ResetPassword from './pages/ResetPassword';

import Screen from './pages/Screen';
import ErrorPage from './pages/ErrorPage'; 
import OnFidoVerification from './pages/OnFidoVerification';
import Layout from './hocs/Layout';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import WaitingListFrom from './hocs/WaitingList';
import PrivateRoute from './components/PrivateRoute';
import { PersistGate } from 'redux-persist/integration/react';

// Create emotion cache
const emotionCache = createCache({
  key: 'css',
  prepend: true, // This ensures MUI styles are loaded first
  speedy: true, // This enables optimization in production
});


function App() {
  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true
          }}>
            <Layout>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/error" element={<ErrorPage errorCode={404} />} />
                <Route path="/documents/rulebooks/:title" element={ <RuleBook /> } />
                <Route path="/screening" element={   <Screen />     } />
                
                <Route path="/forgot_password" element={ <RequestReset />  } />
                <Route path="/reset_password" element={ <ResetPassword />  } />

                {/* Protected routes */}
                <Route path="/investments" element={
                  <PrivateRoute>
                    <Investments />
                  </PrivateRoute>
                } />
                <Route path="/profile" element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                } />
                <Route path="/company/:id" element={
                  <PrivateRoute>
                    <CompanyDetails />
                  </PrivateRoute>
                } />
                <Route path="/deals/:dealId" element={
                  <PrivateRoute>
                    <Order />
                  </PrivateRoute>
                } />
                <Route path="/waiting/:dealId" element={
                  <PrivateRoute>
                    <WaitingListFrom />
                  </PrivateRoute>
                } />
                <Route path="/portfolio" element={
                  <PrivateRoute>
                    <Portfolio />
                  </PrivateRoute>
                } />
                <Route path="/logout" element={
                  <PrivateRoute>
                    <Logout />
                  </PrivateRoute>
                } />
                <Route path="/screening" element={
                  <PrivateRoute>
                    <Screen />
                  </PrivateRoute>
                } />
                <Route path="/verification" element={
                  <PrivateRoute>
                    <OnFidoVerification />
                  </PrivateRoute>
                } />
              

                {/* Catch-all route for undefined paths */}
                <Route path="*" element={<ErrorPage errorCode={404} />} />
              </Routes>
            </Layout>
          </Router>
        </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
}

export default App;