import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { Typography, Button, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import api from '../axiosInstance';
import Cookies from 'js-cookie';
import CSRFToken from '../components/general/CSRFToken';

const ResetPassword = () => {

    const theme = useTheme();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        setLoading(true);

        try {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                    
                }
            }

            const response = await api.post(
                'api/accounts/password_reset/verify/',
                {token, new_password: password},
                config
            );

           

            if (response.status === 200) {
                setMessage('Password successfully reset. You can now login')
            } else {
                setMessage(response.data.error || 'An error occured');
            }
        } catch (error) {
            setMessage(error.response?.data?.error || 'An error occurred. Please try again');        } finally {
            setLoading (false);
        }
};

    return (
        <div style={{ display: 'flex', padding: 20, backgroundColor: theme.palette.primary.main, color: 'white', justifyContent: 'center' }}>
        <Grid item xs={12} lg={10} display='flex' padding='40px'>
              <Grid component={Paper} style={{ padding: '60px' }}>
                    <Typography variant='h4' gutterBottom> Set New Password </Typography>

                    <form onSubmit={handleSubmit} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        
                        <CSRFToken />


                        <FormControl variant='outlined' fullWidth margin='normal' required>
                            <InputLabel htmlFor='password'> New Password </InputLabel>
                            <OutlinedInput
                                id='password'
                                name='password'
                                label='password'
                                type='password'
                                placeholder="New password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}

                            />
                        </FormControl>

                        <br />


                        <FormControl variant='outlined' fullWidth margin='normal' required>
                            <InputLabel htmlFor='confirmPassword'> Repeat New Password</InputLabel>
                            <OutlinedInput
                                id='confirmPassword'
                                name='confirmPassword'
                                label='confirmPassword'
                                type='password'
                                 placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}

                            />
                        </FormControl>

                        <br />
                      
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled = {loading}
                        >
                            {loading? 'Resetting . . .': 'Reset Password'}
                        </Button>

                        {message && (
                           <Typography variant='body2' color='error' style={{ marginBottom: '20px' }}>
                           {message}
                       </Typography>
                        )}

                    </form>

                  
                </Grid>
            </Grid>
      
        </div>
    );

}

export default ResetPassword;