import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { Typography, Button, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import api from '../axiosInstance';
import Cookies from 'js-cookie';
import CSRFToken from '../components/general/CSRFToken';


const RequestReset = () => {

    const theme = useTheme();

    const [ email, setEmail ] = useState('');
    const [message, setMessage ] = useState('');
    const [loading, setLoading ] = useState(false);

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);

        try {
            
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                }
            }

            const response = await api.post(
                'api/accounts/password_reset/',
                {"email": email},
                config,
                
            );

            const data = response.data;

            setMessage(data.message);
        } catch (error) {
            setMessage('An error occured. Please try again');
            console.log(error)
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', padding: 20, backgroundColor: theme.palette.primary.main, color: 'white', justifyContent: 'center' }}>
        <Grid item xs={12} lg={10} display='flex' padding='40px'>
              <Grid component={Paper} style={{ padding: '60px' }}>
                    <Typography variant='h4' gutterBottom> Request Password Reset </Typography>

                    <form onSubmit={handleSubmit} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <CSRFToken />
                    <Typography>
                    Please provide the email associated with the account you need to reset password for.
                    </Typography>   

                        <FormControl variant='outlined' fullWidth margin='normal' required>
                            <InputLabel htmlFor='email'>Your Email</InputLabel>
                            <OutlinedInput
                                id='email'
                                name='email'
                                label='Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}

                            />
                        </FormControl>

                        <br />
                      
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled = {loading}
                        >
                            {loading? 'Sending . . .': 'Send Reset Link'}
                        </Button>

                        {message && (
                           <Typography variant='body2' color='error' style={{ marginBottom: '20px' }}>
                           {message}
                       </Typography>
                        )}

                    </form>

                  
                </Grid>
            </Grid>
      
        </div>
    );
}




export default RequestReset;